import React, { Fragment, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import { FormElement, Checkbox } from "../../../components";
import { agencyActions } from "../../../state/ducks/agency";
import PropTypes from "prop-types";

const CompanyDetailForm = (props) => {
  const {
    t,
    company: {
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
      isForeignerBusiness
    },
    editable,
    cuitText,
    handleInputChange,
    handlePhoneChange,
    agency: { companyTypes },
    titleLabel,
    hiddeTypeCompany,
    hiddeIva,
  } = props;

  const ivaList = [
    { value: "0", name: "No" },
    { value: "1", name: "Si" },
  ];

  return (
    <Fragment>
      <div className='columns is-multiline'>
        <div className='column is-full'>
          <h4>{t(titleLabel)}</h4>
          <hr className='dots' />
        </div>
        <FormElement
          name='cuitCompany'
          onChange={handleInputChange}
          value={cuitCompany}
          label={"CUIT"}
          typeElement={!editable || cuitText ? "text" : "cuit"}
        />
        {!hiddeTypeCompany && (
          <FormElement
            name='companyType'
            onChange={handleInputChange}
            value={companyType}
            label={t("companyType")}
            typeElement={"select"}
            optionsSelect={companyTypes}
            controlClasses='select'
            placeholder={t("select")}
            disabled={!editable}
          />
        )}
        <FormElement
          name='nameCompany'
          onChange={handleInputChange}
          value={nameCompany}
          label={t("businessName")}
          typeElement={!editable ? "text" : "input"}
        />
        {!hiddeIva && (
          <FormElement
            name='iva'
            onChange={handleInputChange}
            value={iva}
            label={"IVA"}
            typeElement={"select"}
            optionsSelect={ivaList}
            controlClasses='select'
            placeholder={t("select")}
            disabled={!editable}
          />
        )}
        <FormElement
          name='usernameCompany'
          onChange={(e) => handleInputChange(e)}
          value={usernameCompany}
          label={`${t("email")}`}
          typeElement={!editable ? "text" : "email"}
        />
        <FormElement
          name='phoneNumberCompany'
          onChange={(e) => handlePhoneChange(e, "phoneNumberCompany")}
          value={phoneNumberCompany}
          label={t("phone")}
          typeElement={!editable ? "text" : "phone"}
        />
{/*         <FormElement
          label={t("isForeigner")}
          typeElement='element'
          value={isForeignerBusiness}
          disabled={!editable}
        >
          <Checkbox
            name='isForeignerBusiness'
            defaultValue={isForeignerBusiness}
            value={isForeignerBusiness}
            onChange={(e) => {
              handleInputChange(e);
            }}
          />
          <div className='value-permission'>{t("yes")}</div>
        </FormElement> */}
      </div>
    </Fragment>
  );
};
CompanyDetailForm.propTypes = {
  t: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  cuitText: PropTypes.bool,
  handleInputChange: PropTypes.func,
  handlePhoneChange: PropTypes.func,
  titleLabel: PropTypes.string,
  hiddeTypeCompany: PropTypes.bool,
  hiddeIva: PropTypes.bool,
};

CompanyDetailForm.defaultProps = {
  editable: false,
  cuitText: false,
  handleInputChange: null,
  handlePhoneChange: null,
  titleLabel: "detailAddress",
  hiddeTypeCompany: false,
  hiddeIva: false,
};

const mapStateToProps = ({ agency }) => ({
  agency,
});
export default compose(
  connect(mapStateToProps, {
    ...agencyActions,
  }),
  withNamespaces()
)(CompanyDetailForm);
