import React, { Component }     from 'react';
import { toast }                from 'react-toastify';
import PropTypes                from 'prop-types';
import { compose }              from 'redux';
import connect                  from 'react-redux/lib/connect/connect';
import { withNamespaces }       from 'react-i18next';
import createHistory            from 'history/createBrowserHistory';
import { initTimer, stopTimer } from '../../../../util/initTimer';
import { TOAST_CONFIG }         from '../../../../config/constants';
import { usersActions }         from '../../../../state/ducks/users';
import { authActions }          from '../../../../state/ducks/auth';
import { agencyActions }        from '../../../../state/ducks/agency';
import { ConfirmAlert }         from '../../../../util/ConfirmAlert';
import Checkbox                 from '../../../../components/Checkbox';
import { FormElement, Switch }          from '../../../../components';
import ReactTooltip                    from "react-tooltip";
import './style.scss';

class ActionButtons extends Component {
  constructor(props) {
    super(props);
    const {
      users: {
        userDetail: {
          active,
        }
      }
    } = props;
    this.state = {
      statusUser: active,
      switchList: [],
      motive: ''
    };
  };

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
  }

  handleRejectUser = () => {
    const { t, fetchRejectUser, showMessage, identification: id_person } = this.props;
    const label = t('confirmStatusUser', { state: 'rechazar' });
    ConfirmAlert(
      { ...this.props },
      () => {
        fetchRejectUser({
          data: { id_person },
          callback: (response) => {
            const { success, data } = response;
            if (success) {
              const history = createHistory();
              toast.dismiss();
              showMessage({
                message: data.message || t('successfulOperation'),
                config: TOAST_CONFIG.SUCCESS,
              });
              setTimeout(() => {
                history.go(0);
              }, 3000);
            }
          },
        });
      },
      label,
    );
  };

  handleApprovedUser = () => {
    const { t, fetchAcceptUser, showMessage, identification: id_person } = this.props;
    const label = t('confirmStatusUser', { state: 'validar' });
    ConfirmAlert(
      { ...this.props },
      () => {
        fetchAcceptUser(
          {
            data: { id_person },
            callback: (response) => {
              const { success, data } = response;
              if (success) {
                const history = createHistory();
                toast.dismiss();
                showMessage({
                  message: data.message || t('successfulOperation'),
                  config: TOAST_CONFIG.SUCCESS,
                });
                setTimeout(() => {
                  history.go(0);
                }, 3000);
              }
            },
          },
        );
      },
      label,
    );
  };

  unsubscribeUser = () => {
    const { t, fetchUnsubscribeUser, showMessage, identification: id_person } = this.props;
    const label = t('confirmUnsubscribeUser', { state: 'baja' });
    const { motive } = this.state;
    ConfirmAlert(
      { ...this.props },
      () => {
        fetchUnsubscribeUser({
          data: {
            id_person,
            reason: motive,
          },
          callback: (response) => {
            const { success, data } = response;
            if (success) {
              const history = createHistory();
              toast.dismiss();
              showMessage({
                message: data.message || t('successfulOperation'),
                config: TOAST_CONFIG.INFO,
              });
              setTimeout(() => {
                history.go(0);
              }, 3000);
            }
          },
        });
      },
      label,
    );
  };

  handleInputChangeMotive = (event) => {
    stopTimer();
    initTimer(this.props);
    const { target: { value: targetValue, name } } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  handleActiveDisable = (event, id) => {
    const item = event.target.name;
    const isChecked = event.target.checked;
    const { switchList } = this.state;
    switchList[id] = isChecked;
    this.setState({ switchList });
    const { t, showMessage, updateExtractionAgency } = this.props;
    const data = {
      id_agency: id,
      extraction: !isChecked ? 0 : 1,
    };
    const status = isChecked ? 'activó' : 'desactivó';
    updateExtractionAgency({
      data,
      callback: (response) => {
        if (response.success) {
          toast.dismiss();
          showMessage({
            message: t('activeDisableExtractionSuccess', { status }),
            config: status === 'activó' ? TOAST_CONFIG.SUCCESS : TOAST_CONFIG.INFO,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t('saveUserDataError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  render() {
    const {
      handleInputChange,
      statusUser,
      users: {
        userDetail: {
          active,
          bind_error,
          statusText,
          type,
          reason,
          id_agency_extractions,
          agency_extractions,
          statusRenaper,
        }
      }
    } = this.props;
    let disabledRenaper = false
    if(statusText === 'Por validar'){
      if(statusRenaper === 'por validar' || statusRenaper === 'rechazado'){
        disabledRenaper = true
      }
    }
    const { t } = this.props;
    const { input, motive, switchList } = this.state;

    return (
      <div className="columns is-multiline">
        {(active === '1' || active === '0')
        && (
          <>
          <FormElement
            label={t('stataUser')}
            typeElement="element"
            value={statusText}
          >
            <Checkbox
              name="statusUser"
              defaultValue={statusUser}
              value={statusUser === '1'}
              onChange={(e) => {
                handleInputChange(e)
              }}
            />
            <div className="value-permission">
              {statusUser === '1' ? t('activeUser') : t('inactiveUser')}
            </div>
          </FormElement>
          {type === 'Agencia' ?
            <>
            <FormElement
              label={t('extractions')}
              typeElement='element'
              >
                <Switch
                  name={`switch-${id_agency_extractions}`}
                  id={`switch-orange-${id_agency_extractions}`}
                  value={switchList[id_agency_extractions] !== undefined ? switchList[id_agency_extractions] : (agency_extractions === 1)}
                  onChange={(event) => {this.handleActiveDisable(event, id_agency_extractions)}}
                  checked={switchList[id_agency_extractions] !== undefined ? switchList[id_agency_extractions] : (agency_extractions === 1)}
                  htmlFor={`switch-orange-${id_agency_extractions}`}
                />
            </FormElement>
            </>
            :
            <FormElement typeElement='element'/>
          }
          <FormElement
            label={`${t('unsubscribe')}`}
            name='motive'
            typeElement="input"
            value={motive}
            placeholder={t('motive')}
            inputClasses={'input-motive'}
            onChange={this.handleInputChangeMotive}
          />
          <div className='motive-required'>  {`(${t('required')})`}</div>
          <div className='column is-12'>
            {motive === '' ?
                ''
              :
              <button
                type="button"
                className="button is-outlined is-danger mr-4px"
                onClick={this.unsubscribeUser}
              >
                {t('unsubscribe')}
              </button>
            }
          </div>
          </>
        )}

        {(active !== '0' && active !== '1' && active !== '5') && (
          <FormElement
            label={t('stataUser')}
            typeElement="text"
            value={statusText}
          />
        )}
        {(active === '5') && (
          <FormElement
            label={t('stataUser')}
            typeElement="text"
            value={`${t('errorCVU')} - ${bind_error}`}
          />
        )}

        {(type === 'Pasajero' && (active === '2' || active === '4' || active === '5')) && (
          <FormElement
            label={t('verificationNewUser')}
            typeElement="element"
            value={statusText}
          >
            <>
              <button
                type="button"
                className="button is-outlined is-success ml-4px mr-4px"
                onClick={() => { this.handleApprovedUser(); }}
                disabled={(active === '4' || disabledRenaper)}
              >
                {t('verify')}
              </button>
              <button
                type="button"
                className="button is-outlined is-danger ml-4px mr-4px"
                onClick={() => {
                  this.handleRejectUser();
                }}
                disabled={(active === '4'  || disabledRenaper)}
              >
                {t('reject')}
              </button>
            </>
          </FormElement>
        )}
        {(active === '6') ?
            <FormElement
              name='motive'
              label={`${t('motive')}`}
              value={reason === null ? t('notMotive') : reason }
              typeElement="text"
            />
          :
            ''
        }
      </div>
    );
  }
}

ActionButtons.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth, agency }) => (
  {
    users,
    auth,
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces(),
)(ActionButtons);
