import React, { Component, Fragment }                           from 'react';
import PropTypes                                                from 'prop-types';
import { compose }                                              from 'redux';
import { connect }                                              from 'react-redux';
import { withNamespaces }                                       from 'react-i18next';
import { initTimer, stopTimer }                                 from '../../util/initTimer';
import { Form, Loading, Panel }                                 from '../../components';
import { AddressDetailForm, CompanyDetailForm, UserDetailForm } from '../userDetail';
import { usersActions }                                         from '../../state/ducks/users';
import { authActions }                                          from '../../state/ducks/auth';
import { agencyActions }                                        from '../../state/ducks/agency';
import { TOAST_CONFIG }                                         from "../../config/constants";
import {validateZipCode} from "../../util";
import DeclarationDetailForm from "../userDetail/declarationDetailForm";
import { toast, ToastContainer } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import "react-confirm-alert/src/react-confirm-alert.css";

class UserAgencyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'first',
      country: 'AR',
      dni: '',
      cuit: '',
      name: '',
      lastname: '',
      phoneNumber: '',
      cuitCompany: '',
      companyType: '',
      nameCompany: '',
      iva: '',
      usernameCompany: '',
      phoneNumberCompany: '',
      province: '',
      location: '',
      city: '',
      address: '',
      zipCode: '',
      finished: false,
      idRubro: '',
      fuLawful: '',
      peExposed: '',
      peCause: '',
      uifObligated: '',
      uifCause: '',
      approve: false,
      datamessage: '',
      isForeigner: false,
      isForeignerBusiness: false
    };
  }


  confirmAlertCuit = () => {
    const { fetchCreateAgency, showMessage, t } = this.props;
    confirmAlert({
      title: '',
      message: this.state.datamessage,
      buttons: [
        {
          label: t('yes'),
          onClick: () => this.callApiList()
          
        },
        {
          label: t('no'),
          onClick: () => this.changeToFirstView()
        }
      ],
      closeOnClickOutside: false,
      closeOnEscape: false,
    });
    setTimeout(this.dismissAll,100);
  
  };

  dismissAll = () =>  toast.dismiss();

  changeToFirstView = () =>
  {
    this.setState({
      view: 'first',
      approve: false,
      finished: false,
    });
  }

  componentDidMount() {
    const {
      clearUserDetail,
      fetchCompanyTypes,
      getProvinces,
      fetchRubrosAfip,
    } = this.props;
    stopTimer();
    initTimer(this.props);
    clearUserDetail();
    fetchCompanyTypes();
    getProvinces();
    fetchRubrosAfip();
  }
  

  callApiList = () => {
    const { fetchCreateAgency, showMessage, t } = this.props;
    const {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
      city: { value: id_city },
      address,
      zipCode,
      idRubro,
      fuLawful,
      peExposed,
      peCause,
      uifObligated,
      uifCause,
      isForeigner,
      isForeignerBusiness
    } = this.state;

    if(!validateZipCode(zipCode)){
      showMessage({
        message: t('zipCodeInvalid'),
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }

    fetchCreateAgency({
      data: {
        approve: this.state.approve,
        business_cuit: cuitCompany,
        business_name: nameCompany,
        email: usernameCompany,
        iva,
        phone_number: phoneNumberCompany,

        address,
        id_city,
        zip_code: zipCode.toUpperCase(),
        is_foreigner: isForeigner,
        is_foreigner_business: isForeignerBusiness,
        name: name,
        lastname: lastname,
        document_number: dni,
        document_number_aux: cuit,
        agent_phone_number: phoneNumber,
        afip_rg: {
          id_rubro: parseInt(idRubro),
          pe_exposed: peExposed ? 1 :0,
          pe_cause: peCause || '',
          fu_lawful: fuLawful ? 1 : 0,
          uif_obligated: uifObligated ? 1 : 0,
          uif_cause: uifCause || '',
        }
      },
      callback: ({ success, data }) => {
        
        if (success) { 
          this.setState({
            finished: true
          });
          window.location.reload();
        }
        if(success == false && data.requestapprove == true )
        {
          this.setState({
            datamessage: data.message,
            approve: true,
          })
          this.confirmAlertCuit()                      
        }
        else{
          this.setState({
            approve: false,
            view: 'first',
            finished: false
          });
        }
      },
      
    }); 
  }

  handlePhoneChange = (value, name) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? !this.state[name] : targetValue;
    this.setState({
      [name]: value,
    });
  };


  handleInputChangeZipCode = ({ target: { value: targetValue, name } }) => {
    const regex = new RegExp('^[a-zA-Z0-9]+$');
    if (targetValue === "" || regex.test(targetValue)) {
      this.setState({
        [name]: targetValue,
      });
    }
  }

  handleVerifyCuit = (ev) => {
    this.setState({
      dni: ev.target.value.substr(2, 8)
        .replace(/^0+/, ''),
    });
  };

  onSubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();
    this.callApiList()
  };

  handleValidator = () => {
    const { t, showMessage } = this.props;
    const {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
      province,
      location,
      city,
      address,
      zipCode,
      idRubro,
      fuLawful,
      peExposed,
      peCause,
      uifObligated,
      uifCause,
    } = this.state;
    if (dni === ''
      || cuit === ''
      || name === ''
      || lastname === ''
      || phoneNumber === ''
      || cuitCompany === ''
      || companyType === ''
      || nameCompany === ''
      || iva === ''
      || usernameCompany === ''
      || phoneNumberCompany === ''
      || province === ''
      || location === ''
      || city === ''
      || address === ''
      || zipCode === ''
      || idRubro === '') {
      showMessage({
        message: t('allRequiredFields'),
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }

    if(peExposed && peCause === ''){
      const motive = 'Persona Expuesta Politicamente';
      showMessage({
        message: t('motiveRequired', {motive}),
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }

    if(uifObligated && uifCause === ''){
      const motive = 'sujeto obligado ante UIF';
      showMessage({
        message: t('motiveRequired', {motive}),
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }

    const pattern =  /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-zA-Z0-9-]{3,20}[\.][a-zA-Z]{2,5}/g;
    const result = pattern.test(usernameCompany);
    if(result !==true){
      showMessage({
        message: t('emailInvalid'),
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }

    return true;
  }

  handleChangeView = (event, view = 'first') => {
    event.preventDefault();
    if (this.handleValidator()) {
      this.setState({
        view,
      });
    }
    toast.dismiss();
  }

  render() {
    const {
      view,
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
      country,
      province,
      location,
      city,
      address,
      zipCode,
      finished,
      idRubro,
      fuLawful,
      peExposed,
      peCause,
      uifObligated,
      uifCause,
      isForeigner,
      isForeignerBusiness
    } = this.state;
    const person = {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      isForeigner: isForeigner
    };
    const company = {
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
      isForeignerBusiness
    };
    const fullAddress = {
      country,
      province,
      location,
      city,
      address,
      zipCode,
    };
    const afip = {
      idRubro,
      fuLawful,
      peExposed,
      peCause,
      uifObligated,
      uifCause,
    };

    const {
      t, users: { loading },
      auth: { loading: loadingAuth, },
      agency: { loading: loadingAgency, },
    } = this.props;

    let { children, submitText } = ''

    if (view === 'first') {
      submitText = t('next');
      children = (
        <Fragment>
          <div className="mb-16px has-text-grey-light has-text-right">{t('allRequiredFields')}</div>
          <CompanyDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            company={company}
            titleLabel={'agencyData'}
            editable

          />
          <AddressDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            handleInputChangeZipCode={this.handleInputChangeZipCode}
            address={fullAddress}
            titleLabel={'addressAgencyInformation'}
            editable
          />
          <UserDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            handleVerifyCuit ={this.handleVerifyCuit}
            person={person}
            editable
            hideEmail={true}
          />
          <DeclarationDetailForm
              handleInputChange={this.handleInputChange}
              afip={afip}
              titleLabel={'declaration'}
              editable={true}
          />
        </Fragment>
      );
    } else if (view === 'second') {
      submitText = t('save');
      children = (
        <Fragment>
          {finished && <h3 className="text-success">{t('successfulRegister')}</h3>}
          <CompanyDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            company={company}
            titleLabel={'agencyData'}
            editable={false}
          />
          <AddressDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            handleInputChangeZipCode={this.handleInputChangeZipCode}
            address={fullAddress}
            titleLabel={'addressAgencyInformation'}
            editable={false}
          />
          <UserDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            handleVerifyCuit ={this.handleVerifyCuit}
            person={person}
            editable={false}
            hideEmail={true}
          />
          <DeclarationDetailForm
              handleInputChange={this.handleInputChange}
              afip={afip}
              titleLabel={'declaration'}
              editable={false}
          />
        </Fragment>
      );
    }

    return (
      <div>
        {(loading || loadingAuth || loadingAgency) && <Loading />}
        <Fragment>
          <Panel titleContentHeader={t('agencyRegistration')} headingText={t('newAgencyForm')}>
            <div className="pr-16px pl-16px pt-0 content-xl-center">
              <Form
                submitText={finished ? t('finish') : submitText}
                nameButton="configButton"
                onSubmit={(e) => {
                  if (view === 'first') {
                    this.handleChangeView(e, 'second')
                  } else if (view === 'second' && !finished) {
                    this.onSubmit(e);
                  } else if (finished) {
                    window.location.reload();
                  }
                }}
                returnText={(view === 'second' && !finished) ? t('return') : null}
                handleActionButton={(event) => {
                  if (view === 'second') {
                    this.handleChangeView(event);
                  }
                }}
              >
                {children}
              </Form>
            </div>
          </Panel>
        </Fragment>
      </div>
    );
  }
}

UserAgencyForm.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
};

const mapStateToProps = ({ users, auth, agency }) => (
  {
    users,
    auth,
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces(),
)(UserAgencyForm);
